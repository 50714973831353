/* eslint-disable vue/valid-template-root */
<template>
  <!-- eslint-disable vue/html-closing-bracket-newline -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <b-row>
    <b-col cols="12">
      <Fieldset id="information" :title="$t('Basic Information')">
        <h2>{{ $t('Shipping Setting') }}</h2>
        <p>{{ $t('Sellect one of kinds of shipping method for your customer order') }}</p>
      </Fieldset>
    </b-col>
    <b-col cols="12">
      <Fieldset id="options" :title="$t('Options')">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2>{{ $t('Customizing shipping') }}</h2>
            <p>{{ $t('Set your own shipping cost calculation rules based on order price or weight') }}</p>
          </div>
          <div>
            <div>
              <b-button
                to="/shipping/customize"
                variant="primary"
                type="submit"
              >
                {{ $t('Set up Customizing shipping') }}
              </b-button>
            </div>
          </div>
        </div>
        <hr>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2>{{ $t('Third-party shipping') }}</h2>
            <p>{{ $t('Shipping rates are calculated based on order’s detail. You only add your Client token to connect') }}</p>
          </div>
          <div>
            <div>
              <b-button
                to="/shipping/third-party"
                variant="primary"
                type="submit"
              >
                {{ $t('Set up Third-party shipping') }}
              </b-button>
            </div>
          </div>
        </div>
      </Fieldset>
    </b-col>
    <app-tour :steps="steps" />
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import general from '@/mixins/index'

export default {
  components: {
    // BFormRadio,
    BRow,
    BCol,
    BButton,
    AppTour,
  },
  mixins: [general],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isShow: false,
      selectedList: [],
      selectedCurrency: {},
      steps: [
        {
          target: '#tour-card',
          header: {
            title: 'Card',
          },
          content: 'This card contains demo for the tour.',
        },
        {
          target: '#tour-card .icon-code',
          header: {
            title: 'View demo Source',
          },
          content: 'If you ever find your self confused, you can click on this code icon to check the source of current demo.',
        },
        {
          target: '#tour-card .btn',
          header: {
            title: 'Trigger Tour',
          },
          content: 'You can click on this button to trigger the tour.',
        },
      ],
    }
  },
}
</script>
<style lang="sass" scoped>
.container-ctrl
  padding-left: 1rem
  padding-right: 1rem

  .box-cate
    background-color: #F7F7F7
    padding: 1rem

    .cate-list
      background-color: #fff
      margin-top: 1rem
      padding: 1rem
      min-height: 25rem

      .list-item
        padding-left: 0
        overflow-y: scroll
        height: 25rem

        .active
          font-weight: 500
          background-color: #f5f5f5
        .item
          display: flex
          justify-content: space-between
          list-style: none
          padding: 10px 15px
          // text-decoration: none
          color: #000
          cursor: pointer
          &.d-none-after
            &::after
              display: none
              opacity: 0

          & > span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          &:hover
            font-weight: 500
            background-color: #f5f5f5

          &::after

            content: ""
            display: inline-block
            vertical-align: middle
            width: 6px
            height: 13px
            margin-left: 1rem
            transition: transform 350ms
            transform: rotate(0)
            border-width: .5rem 0 .5rem .5rem
            border-color: transparent transparent transparent #ccc
            border-style: solid
  .current
    margin-top: 1rem
</style>
